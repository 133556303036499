import request from '../../request'
import qs from 'qs'

//  查询年级列表-下拉框
const EduGrade = (data) => {
    return request.post(`eduGrade/listGLJS`, data).then(res => res.data);
}
//  查询层次列表-下拉框
const EduStage = (data) => {
    return request.post(`eduStage/listGLJS`, data).then(res => res.data);
}
//  查询专业列表-下拉框
const EduProfession = (data) => {
    return request.post(`eduProfession/listGLJS`, data).then(res => res.data);
}
//  查询考试批次-下拉框
const EduExamBatch = (p) => {
    return request.get(`eduExamBatch/selectEduExamBatchListByTea?${qs.stringify(p)}`).then(res => res.data);
}
// 查询年级下的最高学期
const QueryEduTermByEduGradeId = (p) => {
    return request.get(`eduCourseWorkSetTea/queryEduTermByEduGradeId?${qs.stringify(p)}`).then(res => res.data)
}
// 站点-下拉框
const QueryAllSite = (data) => {
    return request.get('eduSite/queryAllSiteByTeacher', data).then(res => res.data)
}
const QueryAllSitess = (data) => {
    return request.get('eduSite/queryAllSiteJS', data).then(res => res.data)
}
// 站点-下拉框-教师
export const QueryTeacherSite = (data) => {
    return request.get('eduSite/queryAllSiteJS', data).then(res => res.data)
}
// 在线作业批阅-批阅人-下拉框
const QueryEduTeacherByCourseId = (p) => {
    return request.get(`eduCourseWorkSetTea/queryEduTeacherByCourseId?${qs.stringify(p)}`).then(res => res.data);
}
export {
    EduGrade,
    EduStage,
    EduProfession,
    EduExamBatch,
    QueryEduTermByEduGradeId,
    QueryEduTeacherByCourseId,
    QueryAllSite
}