
<style scoped lang="less">
.t_work_online_detail {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 0 20px 20px;
  }
}
</style>
<template>
  <div class="t_work_online_detail">
    <div class="stu-module-header">
      <div class="stu-module-title">作业审查</div>
    </div>
    <el-tabs v-model="form.typeStatus"  @tab-click="query">
      <el-tab-pane label="未批阅列表"  name="0"></el-tab-pane>
      <el-tab-pane label="已批阅列表" name="1"></el-tab-pane>
    </el-tabs>
    <div class="content">
      <el-form
        :model="form"
        size="medium"
        label-width="80px"
        inline
        ref="work_detail_ref"
      >
        <el-form-item label="姓名/学号" prop="name">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="函授站" prop="siteName">
          <el-select v-model="form.siteName" clearable>
            <el-option
              v-for="(item, index) in siteNameOptions"
              :key="index"
              :label="item.siteName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级">
          <el-select v-model="form.gradeId" clearable @change="init">
            <el-option
                v-for="(item, index) in gradeOptions"
                :key="index"
                :label="item.gradeName"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="层次">
          <el-select v-model="form.stageId" clearable @change="init">
            <el-option
                v-for="(item, index) in stageOptions"
                :key="index"
                :label="item.stageName"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业">
          <el-select v-model="form.profId" clearable @change="init">
            <el-option
                v-for="(item, index) in professionalOptions"
                :key="index"
                :label="item.profName"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="query" type="primary">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :height="height" stripe>
        <el-table-column label="序号" width="65" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(col, i) in column"
          :key="i"
          :label="col.label"
          :prop="col.prop"
          :min-width="col.width"
          :sortable="col.sortable"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" width="140" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="form.typeStatus === '0'"
              @click="checked(scope.row)"
            >
              批阅
            </el-button>
            <el-button type="text" @click="open(scope.row)"> 查看 </el-button>
            <el-button
              type="text"
              v-if="form.typeStatus !== '0'"
              @click="reject(scope.row)"
            >
              驳回
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.page"
          :page-sizes="[5, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  EduGrade,
  EduProfession,
  EduStage,
  QueryAllSite,
  QueryTeacherSite
} from "../../../../../libs/api/teacher/select";

export default {
  name: "t_work_online_detail",
  mounted() {
    // this.init();
    this.selectInit();
  },
  created() {
    this.query();
  },
  activated() {
    this.query();
    this.checked()
  },
  data() {
    return {
      height: window.innerHeight - 430,

      form: {
        name: "",
        siteName: "",
          gradeId: "",
          stageId: "",
          profId: "",
        typeStatus: "0",
          page: 1,
        pageSize: 5,
      },
      siteNameOptions: [],
      gradeOptions: [],
      stageOptions: [],
      professionalOptions: [],

      tableData: [],
      column: [
        { label: "姓名", prop: "studentName" },
        { label: "函授站", prop: "siteName" },
        { label: "年级", prop: "gradeName" },
        { label: "专业", prop: "profName" },
        { label: "层次", prop: "stageName" },
        { label: "作业名称", prop: "workName" },
        { label: "得分", prop: "score", sortable: true },
        { label: "提交时间", prop: "commitTime", sortable: true },
      ],
      total: 0,
      pageSize: 10,
      current: 1,
    };
  },
  computed: {
    course(){
      return this.$store.getters.getTCourse;
    }
  },
  methods: {
    query() {
      this.openLoadingView()
      this.$axios_supermallData.get("/hnjxjy-core/eduCourseWorkSetTea/isReviewHomeWorkByWorkId?page="+this.form.page+"&pageSize="+this.form.pageSize
          +"&courseId="+this.course.id
          +"&studentInfo="+this.form.name
          +"&siteId="+this.form.siteName
          +"&gradeId="+this.form.gradeId
          +"&proId="+this.form.profId
          +"&stageId="+this.form.stageId
          +"&typeStatus="+this.form.typeStatus
      ).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.total = resp.data.data.total;
          this.loadingView.close()
        }
      })
    },
    init() {},
    selectInit() {

      let data = new FormData();
      // data.append("siteId", this.user.siteId);
      EduGrade(data).then((res) => {
        this.gradeOptions = res.data;
      });
      EduStage(data).then((res) => {
        this.stageOptions = res.data;
      });
      EduProfession(data).then((res) => {
        this.professionalOptions = res.data;
      });
      QueryAllSite(data).then((res) => {
        this.siteNameOptions = res.data;
      });
      // EduStage, EduProfession;
    },
    // 批阅
    checked(row) {
      if (row) {
        const { id, studentId } = row;
        this.$router.push({
          path: "detail/checked",
          query: {
            id,
            studentId,
          },
        });
      }
    },
    open(row) {
      if (row) {
        const { id, studentId } = row;
        this.$router.push({
          path:"detail/preview",
          query:{
            id,
            studentId,
          }
        });
      }
    },
    // 驳回
    reject(row) {
      this.$axios_supermall.post("/hnjxjy-core/stuCourseDetail/withdrawCourseStudentWorkAnswer",{
        workRecordIds:row.id,
        workSetId:row.workId
      }).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("操作成功")
          this.query()
        }else{
          this.$message.error("操作失败")
        }
      })
    },
    handleCurrentChange(page) {
      this.form.page = page;
      this.query();
    },
    handleSizeChange(page){
      this.form.pageSize = page;
      this.query();
    },
    reset() {
      this.$refs["work_detail_ref"].resetFields();
    },
  },
};
</script>
